@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  font-family: "Helvetica Neue", sans-serif;
}
@font-face {
  font-family: Icons;
  font-weight: normal bold;
  font-display: block;
  src: url("./fonts/icons.woff") format("woff");
}