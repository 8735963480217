.ball-1{
    background: radial-gradient(#1F6AA0 45%, transparent 70%);
    z-index: 1;
}

.ball-2{
    background: radial-gradient(#FCB07E 45%, transparent 70%);
    z-index: 2;
}

.ball-3{
    background: radial-gradient(#FFFDD9 45%, transparent 70%);
    z-index: 0;
}

.noise {
    background: url("./images/noise.png");
    mix-blend-mode: plus-lighter;
    z-index: 5;
}


.grid-draw {
    background-size: 96px 96px;
    background-image:
            linear-gradient(to right, rgba(124, 124, 124, 0.25) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(124, 124, 124, 0.25) 1px, transparent 1px);
    background-position: center;
}